import React from 'react';
import styled from '@emotion/styled';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { colors, device } from './layout/GlobalStyles';

const StyledBlogPanels = styled.ul``;

const Panel = styled.li`
    list-style: none;
    margin: 32px 0px;
    a {
        color: ${colors.blue2};
        text-decoration: underline;
        margin: 16px 0px;
    }
    .featured-image {
        margin: 16px 0px;
        border-radius: 15px;
        img {
            border-radius: 15px;
        }
    }
    @media ${device.laptop} {
        display: flex;
        justify-content: space-between;
        .text-container {
            width: 428px;
        }
        .featured-image {
            width: 504px;
            height: 287px;
            img {
                width: 504px;
                height: 287px;
            }
        }
    }
`;

export const BlogPanels: React.FC = ({ blogs }) => {
    return (
        <StyledBlogPanels>
            {blogs.edges.map(
                ({
                    node: {
                        frontmatter: { title, blurb, images, slug },
                    },
                }) => {
                    return (
                        <Panel key={title}>
                            <GatsbyImage
                                className="featured-image image-shadow"
                                alt={images[0].name}
                                image={images[0].childImageSharp.gatsbyImageData}
                            />
                            <div className="text-container">
                                <h3>{title}</h3>
                                <p>{blurb}</p>
                                <Link to={slug}>Continue to read</Link>
                            </div>
                        </Panel>
                    );
                }
            )}
        </StyledBlogPanels>
    );
};
