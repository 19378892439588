import { DefaultLayout } from '../layouts/DefaultLayout';
import { Hero } from '../components/Hero';
import { StaticImage } from 'gatsby-plugin-image';
const breadCrumbNames = ['Services', 'Conditions'];
const breadCrumbUrls = ['/our-services/', '/conditions/'];
import { graphql } from 'gatsby';
import { BlogPanels } from '../components/Blog-Panels';
import { Seo } from '../components/seo';

const Blog: Page = ({ data: { blogs } }) => {
    return (
        <>
            <Seo
                title="Blog Articles  Innovative Medicine"
                description="Find a list of different articles answering all kinds of questions and giving tou what you need to stay informed on our medical services and offerings. "
            />
            <div className="small-container">
                <Hero
                    title="Blog"
                    breadCrumbUrls={breadCrumbUrls}
                    breadCrumbNames={breadCrumbNames}
                >
                    {' '}
                    <StaticImage
                        className=""
                        src="../images/our-blogs-1.jpeg"
                        placeholder="none"
                        quality={100}
                        alt="doctor sitting in office"
                    />
                </Hero>
                <BlogPanels blogs={blogs} />
            </div>
        </>
    );
};

export const query = graphql`
    query BlogQuery {
        blogs: allMdx(filter: { fileAbsolutePath: { regex: "/blog/" } }) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        blurb
                        slug
                        images {
                            id
                            childImageSharp {
                                gatsbyImageData(quality: 100, placeholder: NONE)
                            }
                            name
                        }
                    }
                }
            }
        }
    }
`;

Blog.Layout = DefaultLayout;

export default Blog;
